import { useFetch } from '@/api';

export const getClients = async (params) => {
  const { axiosWrapper } = useFetch();
  const { isSuccess, data } = await axiosWrapper({
    type: 'get',
    url: '/merchants/clients/elastic',
    params: {
      sortBy: 'lastPayment',
      orderBy: 'DESC',
      perPage: 25,
      ...params,
    },
  });
  const result = data.data.map((client) => ({
    id: client.id,
    text: client.clientName,
    value: client.clientEmail,
    ...client,
  }));

  return { isSuccess, data: result };
};

export const сreateClient = async ({ email, name }) => {
  const { axiosWrapper } = useFetch();
  const { isSuccess, errorObject } = await axiosWrapper({
    type: 'post',
    url: 'merchants/clients',
    params: { fullName: name, email },
  });

  return { isSuccess, errorObject };
};
