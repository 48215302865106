<template>
  <div class="d-flex align-items-center">
    <AppText
      class="hover font-medium"
      @click="runSearch(client.name)"
    >
      {{ client.name }}
    </AppText>
    <template v-if="client?.shortId">
      <DotSeparator color="#CCCCCC" />
      <AppText
        color="#949494"
        class="hover"
        @click="runSearch(client?.shortId)"
      >
        #{{ client?.shortId }}
      </AppText>
    </template>
    <DotSeparator color="#CCCCCC" />
    <AppText
      color="#949494"
      class="hover"
      @click="runSearch(client.email)"
    >
      {{ truncatedEmail }}
    </AppText>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue';

import DotSeparator from '@/components/DotSeparator.vue';

import { truncateLongString } from '@/utils/functions';

export default defineComponent({
  components: {
    DotSeparator,
  },
  props: {
    client: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['run-search'],
  setup(props, { emit }) {
    const runSearch = (value) => {
      emit('run-search', value);
    };

    const truncatedEmail = computed(() => truncateLongString(props.client?.email));

    return { runSearch, truncatedEmail };
  },
});
</script>

<style scoped lang="scss">
.hover {
  &:hover {
    cursor: pointer;
    color: var(--color-primary);
  }
}
</style>
