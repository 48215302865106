<script>
import { computed, defineAsyncComponent, defineComponent } from 'vue';

import StatusCellRenderer from '@/components/Modules/AgGrid/Cells/cellRenderers/StatusCellRenderer';

export default defineComponent({
  components: {
    AppBadge: defineAsyncComponent(() => import('@/components/AppBadge.vue')),
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const item = computed(() => props.item.status);
    const row = computed(() => props.item);
    const column = computed(() => ({ field: 'status' }));
    return () => StatusCellRenderer.renderFunction({ item, row, column });
  },
});
</script>
