<template>
  <div class="add-client-wrapper">
    <div class="title">
      <div v-if="isClientWillBeAdded" class="point" />
      <AppText
        size="11px"
        variant="div"
        class="font-medium"
        :opacity="isClientWillBeAdded ? 1 : 0.4"
      >
        {{ headerText }}
      </AppText>
    </div>

    <div v-if="!isClientBlockActive" class="fields">
      <FInput
        ref="emailInputRef"
        v-model="email"
        has-prefix
        autocomplete="none"
        :placeholder="t('component.selectClient.label.placeholder')"
        :disabled="isClientBlockActive"
        :validation-error="mergedErrors.clientEmail || mergedErrors.fullName"
        @focus="onFocus"
        @blur="onBlur"
        @slot-append-click="onCloseIconClick"
      >
        <template #prefix>
          <AppIcon name="user" opacity="0.4" size="18px" is-img-tag />
        </template>
        <template v-if="email" #append>
          <AppIcon name="x-close" size="18px" is-img-tag />
        </template>
      </FInput>

      <Transition name="show-fast">
        <OptionsSelectList
          v-if="isOptionsListVisible"
          :options="filteredOptions"
          :is-searched="Boolean(email?.length)"
          @select="onOptionSelect"
          @add="onClientWillBeAdded"
        />
      </Transition>
    </div>

    <div v-if="isClientBlockActive" class="client-block">
      <div class="wrapper-close-action" @click.stop="onCloseIconClick">
        <AppIcon
          name="x-close-small"
          size="18px"
          class="close-icon"
        />
      </div>

      <OldClientInfo
        v-if="isClientBlockActive && !isNewClient"
        :client="selectedClient"
        :status="status"
      />
    </div>
  </div>
</template>

<script setup>
import {
  computed, ref, watch,
} from 'vue';
import { useThrottleFn } from '@vueuse/core';

import { i18n } from '@/plugins/localization';

import OldClientInfo from './OldClientInfo.vue';
import OptionsSelectList from './OptionsSelectList.vue';

const props = defineProps({
  options: {
    type: Array,
    default: () => [],
  },
  errors: {
    type: Object,
    default: () => {},
  },
  responseErrors: {
    type: Object,
    default: () => {},
  },
});

const emit = defineEmits(['get-clients']);

const email = defineModel('email', { type: String, default: '' });
const name = defineModel('name', { type: String, default: '' });
const isNewClient = defineModel('isNewClient', { type: Boolean, default: false });
const status = defineModel('status', { type: String, default: '' });

const selectedClient = ref({});

const emailInputRef = ref(null);

const isMainInputActive = ref(false);
const onFocus = () => {
  isMainInputActive.value = true;
};
const onBlur = () => {
  setTimeout(() => {
    isMainInputActive.value = false;
    if (!filteredOptions.value.length && email.value.length) {
      isClientWillBeAdded.value = true;
      isNewClient.value = true;
    }
  }, 200);
  if (!filteredOptions.value.length && email.value?.includes('@') && email.value?.length >= 4) {
    name.value = email.value?.split('@')[0];
  }
};

const filteredOptions = computed(() => {
  if (!email.value) {
    return props.options;
  }
  return props.options.filter((el) => {
    const hasTextMatch = el.text
      .toLowerCase()
      .includes(email.value.toLowerCase());
    const hasValueMatch = typeof el.value === 'string'
      ? el.value
        .toLowerCase()
        .includes(email.value.toLowerCase())
      : false;

    return hasTextMatch || hasValueMatch;
  });
});

const isClientWillBeAdded = ref(false);

const onOptionSelect = (option) => {
  email.value = option.value;
  name.value = option.text;
  status.value = option.status;
  selectedClient.value = option;
  isMainInputActive.value = false;
  isClientWillBeAdded.value = false;
  isClientBlockActive.value = true;

  isNewClient.value = false;
};

const getClientsThrottled = useThrottleFn(() => {
  emit('get-clients', email.value);
  const hasMatch = filteredOptions.value.find((el) => el.value === email.value);
  if (hasMatch) {
    setTimeout(() => {
      onOptionSelect(hasMatch);
    }, 0);
  }
}, 512, true);

const onEmailChange = () => {
  getClientsThrottled();
};

const onClientWillBeAdded = () => {
  isClientWillBeAdded.value = true;
  emailInputRef.value.$el.querySelector('.el-input__inner').focus();
};

const isClientBlockActive = ref(false);

watch(computed(() => email.value), onEmailChange, { immediate: true });

const onCloseIconClick = () => {
  email.value = '';
  name.value = '';
  status.value = '';
  isClientBlockActive.value = false;
  isMainInputActive.value = false;
  isClientWillBeAdded.value = false;
};

const mergedErrors = computed(() => ({
  ...props.errors,
  ...props.responseErrors,
}));

const { t } = i18n.global;
const headerText = computed(() => (isClientWillBeAdded.value
  ? t('component.selectClient.label.newClientTitle')
  : t('component.selectClient.label.detailsTitle')
).toUpperCase());

const isOptionsListVisible = computed(() => !isClientWillBeAdded.value
  && isMainInputActive.value);

watch(email, (newVal, oldVal) => {
  if (isClientWillBeAdded.value && newVal.length < oldVal.length) {
    isClientWillBeAdded.value = false;
    isMainInputActive.value = true;
    name.value = '';
  }
});
</script>

<style scoped lang="scss">
.add-client-wrapper {
  margin-bottom: 20px;
  .title {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .point {
      width: 8px;
      height: 8px;
      margin-right: 10px;
      background: var(--color-primary);
      border-radius: 50%;
    }
  }

  .fields {
    display: flex;
    position: relative;

    :deep(.el-button) {
      width: 44px !important;
      padding: 0 22px;

      svg {
        margin-top: -2px;
        path {
          stroke: #ffffff;
        }
      }
    }

    :deep(.slot-append) {
      right: 10px;
      cursor: pointer;

      .icon {
        opacity: 0.4;
      }
    }
  }

  :deep(.f-input) {
    margin-bottom: 0;
  }

  .client-block {
    position: relative;

    .wrapper-close-action {
      position: absolute;
      z-index: 10;
      top: -7px;
      right: -12px;

      border: 2px solid var(--color-white);
      background: #e6e6e6;
      border-radius: 50%;
      cursor: pointer;

      width: 28px;
      height: 28px;
      @include flex-center;

      :deep(.icon) {
        cursor: pointer;
        @include transition-base('fill');
      }

      &:hover {
        background: #FBF3EC;
        :deep(.close-icon path) {
          fill: var(--color-primary);
        }
      }
    }
  }
}

:deep(.el-input__inner) {
  position: relative;
  &:focus {
    z-index: 1;
  }
  + .el-input__prefix {
    z-index: 1;
  }
}

:deep(.f-input) {
  .slot-append {
    z-index: 1;
  }
}
</style>
