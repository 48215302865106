<template>
  <div class="options">
    <CustomScroll style="max-height: 258px;" :has-full-height="false">
      <template v-for="(option) in options.slice(0, 3)" :key="option[optionValue]">
        <div class="option-item" @click="onClick(option)">
          <div v-if="option.lastPayment && !isSearched" class="option-icon">
            <AppIcon
              name="clock"
              :opacity="0.4"
              size="18px"
              is-img-tag
            />
          </div>
          <div class="option-info">
            <AppText
              variant="div"
              size="12px"
              mt="8px"
              class="font-medium"
            >
              {{ option[optionText] }}
            </AppText>
            <AppText
              variant="div"
              size="12px"
              mt="5px"
              mb="10px"
            >
              {{ option[optionValue] }}
            </AppText>
          </div>
        </div>
      </template>

      <div class="new-client" @click.stop="onAddClient">
        <AppIcon name="plus" size="18px" fill="var(--color-primary)" />
        <AppText ml="12px">
          {{ t('component.selectClient.label.addNew') }}
        </AppText>
      </div>
    </CustomScroll>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    options: {
      type: Array,
      default: () => {},
    },
    optionText: {
      type: String,
      default: 'text',
    },
    optionValue: {
      type: String,
      default: 'value',
    },
    isSearched: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['select', 'add'],
  setup(props, { emit }) {
    const onClick = (option) => {
      emit('select', option);
    };

    const onAddClient = () => {
      emit('add');
    };

    return {
      onClick,
      onAddClient,
    };
  },
});
</script>

<style scoped lang="scss">
.options {
  position: absolute;
  width: 100%;
  z-index: 9;
  top: 54px;
  overflow: hidden;

  background: var(--color-white);
  outline: 1px solid var(--el-border-color-light);
  box-shadow: var(--el-box-shadow-light);
  border-radius: 8px;

  max-height: 258px;

  .new-client {
    height: 44px;
    padding: 0 12px;
    display: flex;
    align-items: center;
    color: var(--color-primary);

    &:hover {
      background: var(--color-black-005);
      cursor: pointer;
    }
  }
}

.option-item {
  border-bottom: 1px solid #E6E6E6;
  display: flex;
  padding-left: 16px;

  &:hover {
    background: var(--color-black-005);
    cursor: pointer;
  }

  .option-icon {
    width: 18px;
    align-self: center;
    margin-right: 12px;
    margin-left: -4px;
  }

  .option-info {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 52px;
  }
}
</style>
