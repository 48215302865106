<template>
  <div class="old-client-info" :class="{ blocked: status === 'DO_NOT_DISTURB' }">
    <div class="d-flex align-items-center">
      <div class="icon">
        <AppIcon name="notifications-bell-off" size="24px" />
      </div>
      <AppText
        variant="div"
        size="16px"
        class="font-medium"
        color="var(--color-black)"
      >
        {{ client.clientName }}
      </AppText>
      <AppText
        variant="div"
        size="12px"
        ml="10px"
        class="short-id"
      >
        #{{ client.clientId }}
      </AppText>
      <AppText
        variant="div"
        class="flex-grow-1 is-right"
      >
        {{ truncatedEmail }}
      </AppText>
    </div>
    <div class="d-flex justify-content-between">
      <div class="d-flex flex-column">
        <div class="detail-title">
          {{ t('component.selectClient.totalRevenue') }}
        </div>
        <div class="detail-value">
          <template v-if="client?.revenue">
            <i18n-t keypath="component.selectClient.paidPaymentsAmount" :plural="client?.paymentsCount">
              <template #amount>
                <CurrencyFormatter
                  :value="client?.revenue"
                  is-account-currency
                  size="13px"
                  class="font-medium bold-value mr-1"
                />
              </template>
              <template #paymentsAmount>
                {{ client?.paymentsCount }}
              </template>
            </i18n-t>
          </template>
          <template v-else>
            <span class="bold-value">-</span>
          </template>
        </div>
      </div>
      <div class="d-flex flex-column">
        <div class="detail-title is-right">
          {{ t('component.selectClient.lastPaid') }}
        </div>
        <div class="detail-value is-right bold-value">
          <DateFormatter :date="client?.lastPayment" :format="dateFormat" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue';

import CurrencyFormatter from '@/components/Helpers/CurrencyFormatter.vue';
import DateFormatter from '@/components/Helpers/DateFormatter.vue';

import { useSettings } from '@/composables/useSettings';
import { truncateLongString } from '@/utils/functions';

export default defineComponent({
  components: {
    DateFormatter,
    CurrencyFormatter,
  },
  props: {
    client: {
      type: Object,
      default: () => {},
    },
    status: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { dateFormat } = useSettings();
    const truncatedEmail = computed(() => truncateLongString(props.client?.clientEmail));

    return { dateFormat, truncatedEmail };
  },
});
</script>

<style scoped lang="scss">
.old-client-info {
  background: var(--color-black-003);
  border: 1px solid var(--color-black-01);
  border-radius: 4px;
  padding: 20px 40px 20px 20px;
  color: var(--color-black-04);
  font-size: 13px;

  &.blocked {
    border: 2px solid #E9A848;

    .icon {
      display: unset !important;
    }
  }

  & > :first-child {
    border-bottom: 1px solid var(--color-black-01);
    padding-bottom: 16px;
    margin-bottom: 16px;

    .icon {
      width: 24px;
      height: 24px;
      margin-right: 10px;
      display: none;
    }
  }

  .short-id {
    background: var(--color-black-003);
    border-radius: 4px;
    padding: 0 4px;
  }

  .detail-title {
    margin-bottom: 6px;
  }

  .bold-value {
    @include font-medium;
    color: var(--color-black);
    :deep(.date) {
      @include font-medium;
      color: var(--color-black);
    }
  }
}

.is-right {
  text-align: right;
}
</style>
