<template>
  <div class="d-flex align-items-center">
    <TooltipCopy is-icon-hidden>
      <AppText
        variant="div"
        opacity="0.5"
        style="white-space: nowrap;"
      >
        {{ uuid }}
      </AppText>
    </TooltipCopy>
    <DotSeparator color="#ccc" margin="0 12px" />
    <StatusBadge v-if="status === 'EXPIRED'" style="margin-left: -12px;" :item="{ status, state }" />
    <div
      v-else
      class="font-medium status"
      :style="{
        '--color': color,
      }"
    >
      {{ statusText }}
    </div>

    <SmallActionButton @action="$emit('update')" />
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue';

import DotSeparator from '@/components/DotSeparator.vue';
import SmallActionButton from '@/components/Buttons/SmallActionButton.vue';
import TooltipCopy from '@/components/TooltipCopy.vue';
import StatusBadge from '@/components/StatusBadge.vue';

import { i18n } from '@/plugins/localization';
import { makeFirstLetterUppercased } from '@/utils/functions';
import { STATUS } from '@/common/status';

export default defineComponent({
  components: {
    DotSeparator,
    TooltipCopy,
    SmallActionButton,
    StatusBadge,
  },
  props: {
    uuid: {
      type: String,
      default: '',
    },
    status: {
      type: String,
      default: '',
    },
    state: {
      type: String,
      default: '',
    },
    cancellationReason: {
      type: String,
      default: null,
    },
  },
  emits: ['update'],
  setup(props) {
    const color = computed(() => {
      switch (props.status) {
        case 'NEW':
          return 'var(--color-primary)';
        case 'WAITING':
          return '#3D7EB1';
        case 'ACTIVE':
          return '#50A621';
        case 'COMPLETE':
          return '#50A621';
        case 'CANCELLED':
          return 'rgba(0, 0, 0, 0.4)';
        case 'UNCONFIRMED':
          return '#784A20';
        case 'UNDERPAID':
          return '#862A25';
        case 'EXPIRED':
          return 'rgba(0, 0, 0, 0.5)';
        default:
          return 'var(--color-black)';
      }
    });

    const { t } = i18n.global;

    const statusText = computed(() => (props.cancellationReason ? `Cancelled by ${makeFirstLetterUppercased(props.cancellationReason)}` : t(STATUS[props.status])));

    return {
      statusText,
      STATUS,
      color,
    };
  },
});
</script>

<style scoped lang="scss">
.status {
  font-size: 13px;
  margin-right: 20px;
  color: var(--color)
}

:deep(.cell-status) {
  background-color: unset;
  border: unset;
}
</style>
